import React from 'react';
import Layout from '../components/layout';
import "../styles/registerStyles.scss";
import { AnimatePresence, motion } from 'framer-motion';
import Footer2 from '../components/footer2';

const register = props => {
    return <Layout>
        <AnimatePresence>
        <motion.div 
               animate={{y:0 , opacity: 1}}
               initial={{ y: 72, opacity: 0 }}
               transition={{
                 duration: 1,
                 ease: [0.6, 0.05, -0.01, 0.9],
               }}
        className="register-container">
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd9SZqsaDUcCvaO4WFbnLEIJbKSIq-2gSQ9PhleVU9FansLSA/viewform?embedded=true" className="mx-auto w-11/12 mt-4" height="1110" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        <Footer2/>
        </motion.div>
        </AnimatePresence>

    </Layout>
}

export default register
